import React from 'react';
import {Helmet} from 'react-helmet';
import {YMaps} from 'react-yandex-maps';
import SlideshowSection from './component/SlideshowSection';
import AboutSection from './component/AboutSection';
import ServicesSection from './component/ServicesSection';
import PhotosSection from './component/PhotosSection';
import ContactSection from './component/ContactSection';

const App = () => {
    return (
        <YMaps
            query={{lang: 'ru_RU'}}
        >
            <Helmet
                defaultTitle="Peaky Blade — самый атмосферный барбершоп"
            />

            <SlideshowSection/>

            <AboutSection/>

            <ServicesSection/>

            <PhotosSection/>

            <ContactSection/>
        </YMaps>
    );
};

export default App;
