import React from 'react';
import styled from 'styled-components';
import {Map, Placemark} from 'react-yandex-maps';

const ContactSection = () => {
    return (
        <section className="section">
            <div className="container">
                <h2>Контакты</h2>

                <Contacts>
                    <Contact href="tel:+74932343366">+7 (4932) 34-33-66</Contact>
                    <Contact href="https://goo.gl/maps/dzTCMQZimf9Jv4Hm6" target="_blank">Иваново, Бубнова, 40А</Contact>
                    <Contact href="https://vk.com/peakyblade_ivanovo" target="_blank">ВК</Contact>
                    <Contact href="https://wa.me/79051093366" target="_blank">WhatsApp</Contact>
                    <Contact href="https://t.me/peakyblade_ivanovo37" target="_blank">Telegram</Contact>
                </Contacts>


                <YandexMap
                    defaultState={{
                        center: [56.990557, 40.990117],
                        controls: ['zoomControl', 'fullscreenControl'],
                        zoom: 15,
                    }}
                    modules={['control.ZoomControl', 'control.FullscreenControl']}
                >
                    <Placemark defaultGeometry={[56.990557, 40.990117]} />
                </YandexMap>
            </div>
        </section>
    );
};

const Contact = styled.a`
    color: #8d8d8d;
    cursor: pointer;
    display: inline-flex;
    font-weight: 700;
    letter-spacing: 1px;
    text-decoration: none;
    text-transform: uppercase;
    white-space: nowrap;
    
    &:hover {
        opacity: 0.7;
    }
`;

const Contacts = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    
    @media (min-width: 768px) {
        flex-direction: row;
        line-height: 1;
    }
    
    @media (min-width: 992px) {
        flex-direction: row;
        letter-spacing: 3px;
        line-height: 1;
    }
`;

const YandexMap = styled(Map)`
    margin: 30px 0;
    height: 300px;
    width: 100%;
    
    @media (min-width: 768px) {
        height: 400px;
    }
`;

export default ContactSection;
